import React, { useState } from "react";
import { Container, Row, Col, Breadcrumb, Form, Button, Spinner  } from 'react-bootstrap';
import { Link } from "gatsby"
import Layout from "../../components/layout/layout";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { careers } from "../../utils/APIs/General_API"
import { useFormik } from "formik";
import * as Yup from "yup";
import 'react-toastify/dist/ReactToastify.css';
import "yup-phone";

import "./career.scss";


const initialValues = {
    name: "",
    email: "",
    phone: '',
}

const validationSchema = Yup.object({
    name: Yup.string().max(100).required('Name is required field!'),
    email: Yup.string().max(100).email('Please enter valid Email').required('Email is required field!'),
    phone: Yup.string().required('Contact Number is required field!').phone('Please enter valid phone number'),
});


const CareerPage = () => {
    

    const [formMessages, setFormMessage] = useState('');
	const [initialValue, setInitialValue] = useState(initialValues);
    const [isValidate, setIsvalidate] = useState(false);
    const [loginButtonLoading, setLoginButtonLoading] = useState(false);
    const [attachment, setAttachment] = useState(null);
    const [invalidFile, setInvalidFile] = useState(null);


	const { handleSubmit, handleChange, values, setFieldValue, errors, resetForm } = useFormik({
        enableReinitialize: true,
        initialValues: initialValue,
        validationSchema,
        validateOnChange: isValidate,
        onSubmit(values, { resetForm }) {
            if(invalidFile != null) {
                return false;
            }
            setFormMessage('');
            setIsvalidate(true)
            setLoginButtonLoading(true)
            
            var formData = new FormData();
            formData.append('name', values.name)
            formData.append('email', values.email)
            formData.append('phone', values.phone)
            if(attachment != null){
                formData.append('file', attachment)
            }

            return sendMessage(formData, resetForm, values);
        }
    });

    const handleAttachment = (event) => {
        console.log(event.target.files[0]);
        setInvalidFile(null)
        setAttachment(null)
        const imageFile = event.target.files[0];
        const imageSize = event.target.files[0].size / 1024 / 1024;
        const url = URL.createObjectURL(event.target.files[0]);
        if(imageSize > 2) {
            setInvalidFile('Uploaded File Too Large, less than 2 MB needed');
            return false;
        }
        setInvalidFile(null)
        setAttachment(imageFile)
    }

    const sendMessage = (list, resetForm, values) => {
        careers(list).then(res => {
            const resData = res.data;
            if (resData && resData.code == 200) {
                setLoginButtonLoading(false)
                resetForm({})
                toast.dismiss();
                toast.success("Thanks you, we will contact you shortly!", {
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }).catch(err => {
            setLoginButtonLoading(false)
            const error = err.response;
            console.log(error);
            if (error.status == 422) {
                if (error?.data?.message?.errors) {
                    {
                        if (error?.data?.message?.errors?.email) {
                            setFormMessage(error?.data?.message?.errors?.email);
                        } else if (error?.data?.message?.errors?.phone) {
                            setFormMessage(error?.data?.message?.errors?.phone);
                        } else if (error?.data?.message?.errors?.name) {
                            setFormMessage(error?.data?.message?.errors?.name);
                        } else if (error?.data?.message?.errors?.message) {
                            setFormMessage(error?.data?.message?.errors?.message);
                        } else {

                        }
                    }
                }
            } else {
                setFormMessage('Something Went Wrong!');
            }
        })
    }


    return (
        <Layout>
            <>
                <section className="sec-common-page-heading" style={{ backgroundImage: "url('../images/laughing-doctors.jpg')" }}>
                    <Container>
                        <div className="d-flex justify-content-between align-items-center">
                            <h2>Career</h2>
                            <Breadcrumb>
                                <li className="breadcrumb-item">
                                    <Link to="/">Home</Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    Career
                                </li>                                
                            </Breadcrumb>
                        </div>
                    </Container>
                </section>


                <section className="sec-career">
                    <Container> 
                        <div className="career-content">
                            <div className="title">Apply now</div>
                            <h4 className="mb-2">Careers/ work for us (how to apply ) link for an application and browse to attach the cv and documents.</h4>
                            <p className="mb-4">At FHS, we value our workers and regard them as our best asset. We therefore take great care in recruiting, training and supervising our staff. Among some of the key qualities we seek to find in potential candidates are individuals who show empathy and compassion for the vulnerable people we serve and more importantly that they possess a caring disposition.</p>

                            <h5 className="mb-2">Top pay rates, and flexible job hours</h5>
                            <p className="mb-4">We offer top pay rates, weekly pay and flexible job hours to fit your lifestyle. We also have an extensive network of nursing homes that will give you the freedom and opportunity to work close to home. We offer a referral bonus program.</p>

                            <h5 className="mb-2">Work incentives</h5>
                            <p className="mb-4">Post registration education and practice opportunities are made available, to enable you to keep up-to-date with current best practice by assisting with all mandatory and specialist trainings.</p>

                            <h5 className="mb-2">Welcome to the FHS Revalidation Portal. We are committed to assist our agency nurse’s preparation of revalidation.</h5>
                            <p>We will provide agency nurses with the tools required to support revalidation – a process which come into effect in April 2016 and builds upon the Nursing and Midwifery Council’s (NMC) current registration renewal process.</p>
                            <p className="mb-4">Supervision and appraisal:  we nurture our staff and provide services that support them to grow and progress in their chosen career path, through regular supervision, clinical competency and annual appraisal.</p>

                            <h5 className="mb-3">Specialist courses for nurses</h5>
                            <ul className="mb-5">
                                <li><i className="bi bi-check-lg"></i>Diabetes and insulin management</li>
                                <li><i className="bi bi-check-lg"></i>Syringe driver management</li>
                                <li><i className="bi bi-check-lg"></i>Caring for patients with multiple sclerosis/ Tracheostomy Care.</li>
                                <li><i className="bi bi-check-lg"></i>Neurological observations</li>
                                <li><i className="bi bi-check-lg"></i>Catheterisation (Male and Female)</li>
                                <li><i className="bi bi-check-lg"></i>Principles of Nursing Practice</li>
                                <li><i className="bi bi-check-lg"></i>Clinical Governance</li>
                                <li><i className="bi bi-check-lg"></i>End of Life Care</li>
                                <li><i className="bi bi-check-lg"></i>Supporting People’s Nutritional Needs</li>
                            </ul>
                        </div>                                
                    </Container>
                </section>


                <section className="sec-career-form">
                    <Container>
                        <div className="text-center mb-5">
                            <div className="title">Application Form</div>
                        </div>
                    </Container>
                    <Container>
                        <Row>
                            <Col lg={{ span:8, offset:2 }}>
                                <div className="career-form">
                                    <Form onSubmit={handleSubmit} autoComplete="off" encType="multipart/form-data">
                                        <Form.Group className="mb-4" controlId="formFullName">
                                            <Form.Control
                                                type="text"
                                                placeholder="Name"
                                                id="name"
                                                name="name"
                                                value={values.name}
                                                onChange={handleChange}
                                            />
                                            {errors.name ? <p className="error text-danger">{errors.name}</p> : null}
                                        </Form.Group>
                                        <Form.Group className="mb-4" controlId="formEmail">
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Email"
                                                id="email"
                                                name="email"
                                                value={values.email}
                                                onChange={handleChange}
                                            />
                                            {errors.email ? <p className="error text-danger">{errors.email}</p> : null}
                                        </Form.Group>
                                        <Form.Group className="mb-4" controlId="formPhone">
                                            <Form.Control
                                                type="text"
                                                placeholder="Phone"
                                                id="phone"
                                                name="phone"
                                                value={values.phone}
                                                onChange={handleChange}
                                            />
                                            {errors.phone ? <p className="error text-danger">{errors.phone}</p> : null}
                                        </Form.Group>
                                        <Form.Group controlId="formFile" className="mb-4">                                            
                                            <Form.Control type="file" placeholder="upload Resume" name="attachment" onChange={handleAttachment} accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*"/>
                                            {invalidFile && <span className="error text-danger">{invalidFile}</span>}
                                        </Form.Group>
                                        <div className="text-end">
                                            <button type="submit" className="btn btn-primary" disabled={loginButtonLoading}>
                                                {
                                                    loginButtonLoading ? (
                                                        <Spinner animation="border" size="sm" />
                                                    ) : (
                                                        <span>Submit</span>
                                                    )
                                                }
                                            </button>
                                        </div>
                                        <Form.Group className="mt-3">
                                            {formMessages ? <p className="error text-danger alert alert-danger">{formMessages}</p> : null}
                                        </Form.Group>
                                    </Form>
                                </div>        
                            </Col>
                        </Row>
                    </Container>
                </section>


                {/* <section className="sec-service-page">
                    <Container>

                        <div className="service-content">
                            <h5 className="mb-3">Specialist courses for nurses</h5>
                            <ul className="mb-5">
                                <li><i className="bi bi-check-lg"></i>Diabetes and insulin management</li>
                                <li><i className="bi bi-check-lg"></i>Syringe driver management</li>
                                <li><i className="bi bi-check-lg"></i>Caring for patients with multiple sclerosis/ Tracheostomy Care.</li>
                                <li><i className="bi bi-check-lg"></i>Neurological observations</li>
                                <li><i className="bi bi-check-lg"></i>Catheterisation (Male and Female)</li>
                                <li><i className="bi bi-check-lg"></i>Principles of Nursing Practice</li>
                                <li><i className="bi bi-check-lg"></i>Clinical Governance</li>
                                <li><i className="bi bi-check-lg"></i>End of Life Care</li>
                                <li><i className="bi bi-check-lg"></i>Supporting People’s Nutritional Needs</li>
                            </ul>
                        </div>
                        
                    </Container>

                </section> */}

            </>
        </Layout>
    )
}

export default CareerPage
